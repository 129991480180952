import moment from 'moment'
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { required } from './config'

import DatePickerInput from 'components/Common/Form/Input/DatePickerInput'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import { DMY_CASUAL_SHORT_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'

interface Props
  extends Omit<React.ComponentProps<typeof DatePickerInput>, 'onChange'> {
  name: string
}

function RHFDatePickerInput(props: Props) {
  const { noValidationSpacing = true, onDateChange, disabled, ...rest } = props
  const { control, setValue, setError, watch } = useFormContext()

  const date = watch(props.name)

  // Clearing the value doesn't properly update DatePickerInput and its underlying DateInput,
  // because of crazy internal state stuff and the subtleties of null vs. undefined values.
  // As a workaround, change the key to replace the entire component instance.
  // This is awful, but changing the way those components work is too difficult since a bunch
  // of stuff depends on them that I don't want to test.
  const [inputKey, setInputKey] = useState(0)

  const {
    field: { onChange, ...controllerProps },
    fieldState: { error },
  } = useController({
    name: props.name,
    control,
    rules: {
      required: props.required && required,
    },
  })

  const handleDateChange = useCallback(
    (val?: Date) => {
      if (val) {
        setValue(props.name, moment(val).format(ISO_DATE_FORMAT), {
          shouldDirty: true,
          shouldValidate: true,
        })
      } else if (!props.required) {
        setValue(props.name, null, {
          shouldDirty: true,
          shouldValidate: true,
        })
        setInputKey((key) => key + 1)
      }
      onDateChange?.(val)
    },
    [props.required, props.name, onDateChange, setValue],
  )

  const getCustomError = useCallback(
    (msg: string) => {
      setError(props.name, {
        message: msg,
        type: 'pattern',
      })
    },
    [props.name, setError],
  )

  const clear: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> =
    useCallback(
      (e) => {
        e.preventDefault()
        e.stopPropagation()
        setValue(props.name, null, {
          shouldTouch: true,
          shouldValidate: true,
        })
        setInputKey((key) => key + 1)
      },
      [setValue, props.name],
    )

  useEffect(() => {
    if (!error) {
      setInputKey((key) => key + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <DatePickerInput
      {...rest}
      {...controllerProps}
      placeholder="Select date"
      manualError={!!error?.message}
      disabled={disabled}
      key={inputKey}
      noValidationSpacing={noValidationSpacing}
      required={props.required}
      startIcon={<LineCalendarIcon />}
      endIcon={
        date &&
        !disabled &&
        !props.required && (
          <IconButton kind="tertiary" variant="ghost" onClick={clear}>
            <LineTimesIcon colour="neutral-one" />
          </IconButton>
        )
      }
      onDateChange={handleDateChange}
      getCustomError={getCustomError}
      invalidErrorMessage={error?.message}
      displayFormat={DMY_CASUAL_SHORT_FORMAT}
    />
  )
}

export default RHFDatePickerInput
