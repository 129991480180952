import { useCallback, useContext } from 'react'

import { useAppDispatch } from 'hooks/reduxHooks'
import TripContext from 'tripPlanner/contexts/TripContext'
import {
  clearCurrentSectionId,
  clearCurrentTripId,
  setCurrentSectionId,
  setCurrentTripId,
} from 'tripPlanner/reducers/actions'

type Args = { sectionId?: string; tripId: string }

export default function useImmersiveModeHandlers() {
  const dispatch = useAppDispatch()
  const tripContext = useContext(TripContext)
  const { displayContext } = tripContext ?? {}

  const setImmersiveMode = useCallback(
    ({ sectionId, tripId }: Args) => {
      if (displayContext !== 'tripPlanner') {
        return
      }

      dispatch(setCurrentTripId(tripId))

      if (sectionId) {
        dispatch(setCurrentSectionId(sectionId))
      }
    },
    [dispatch, displayContext],
  )

  const removeImmersiveMode = useCallback(() => {
    dispatch(clearCurrentTripId())
    dispatch(clearCurrentSectionId())
  }, [dispatch])

  return { setImmersiveMode, removeImmersiveMode }
}
